import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Seo from "../components/Seo"
import Hero from '../components/Hero'
import CityMapHome from '../components/CityMapHome'
import SignUpSection from '../components/SignUpSection'


export default function VotingPlaces({ data }) {


  return(
    <>
      <Seo title="Voting places" />

      <Hero 
        headline="Find your voting place"
        size = {'medium'}
      >
        <StaticImage 
          className="hero__picture"
          src="../../static/media/updates-hero.jpg" 
          alt="" 
          loading="eager"
        />
      </Hero>

      <CityMapHome headline="Choose your ward to see your candidates and how to vote"/>

      <SignUpSection />
    </>
  )
}